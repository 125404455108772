<template>
    <div>
        <transition name="fade">
            <router-view />
        </transition>
        <Loading :isLoading="isLoading" />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import firebase from '../../firebase'
import Loading from './Loading.vue'
import('@fortawesome/fontawesome-free/css/all.css')
import('@fortawesome/fontawesome-free/js/all.js')
export default {
    components: { Loading },
    data() {
        return {
            isLoading: true
        }
    },
    computed: mapState({
        user: state => state.user
    }),
    mounted() {
        firebase.auth().onAuthStateChanged(user => {
            this.$store.commit('setUser', user)
            this.isLoading = false
            if(!user) this.$router.push({ name: 'Login' }).catch(()=>{})
            else if(this.$route.name === 'Login') this.$router.push({ name: 'AdminMain' }).catch(()=>{})
        });
    }

}
</script>