<template>
        <!-- Preloader -->
        <div class="preloader" :class="{'show': !isLoading}">
            <img v-show="isLoading" class="animation_shake" src="@/assets/admin_logo.png" height="60" width="60">
        </div>
</template>

<script>
export default {
    props: ['isLoading']
}
</script>

<style scoped>
.preloader {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--bs-dark);
    z-index: 5;
    transition: all .5s ease-in-out;
}

.preloader.show {
    bottom: 100%;
}

.animation_shake {
    -webkit-animation: shake 1500ms infinite;
    animation: shake 1500ms infinite;
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate(2px, 1px) rotate(0deg);
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-2deg);
    transform: translate(-1px, -2px) rotate(-2deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0) rotate(3deg);
    transform: translate(-3px, 0) rotate(3deg);
  }
  30% {
    -webkit-transform: translate(0, 2px) rotate(0deg);
    transform: translate(0, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(2px, 1px) rotate(-2deg);
    transform: translate(2px, 1px) rotate(-2deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(4deg);
    transform: translate(-1px, -1px) rotate(4deg);
  }
  90% {
    -webkit-transform: translate(2px, 2px) rotate(0deg);
    transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: translate(2px, 1px) rotate(0deg);
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-2deg);
    transform: translate(-1px, -2px) rotate(-2deg);
  }
  20% {
    -webkit-transform: translate(-3px, 0) rotate(3deg);
    transform: translate(-3px, 0) rotate(3deg);
  }
  30% {
    -webkit-transform: translate(0, 2px) rotate(0deg);
    transform: translate(0, 2px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(2px, 1px) rotate(-2deg);
    transform: translate(2px, 1px) rotate(-2deg);
  }
  80% {
    -webkit-transform: translate(-1px, -1px) rotate(4deg);
    transform: translate(-1px, -1px) rotate(4deg);
  }
  90% {
    -webkit-transform: translate(2px, 2px) rotate(0deg);
    transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

</style>